import * as React from 'react';
import BasePage from "../BasePage"
  

export default function Podcasts() {
  return (
    <BasePage>
      Podcasts are coming!
    </BasePage>
  );
}
